import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import FA from "react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import "./App.css";

const localStorageKey = "formDataInLocalStorage";

function formatDate(date) {
  //return Intl.DateTimeFormat("gr-el").format(new Date(date));

  return moment(new Date(date)).tz("Europe/Athens").format("DD/MM/YYYY");
}

//const apiUrl = "https://localhost:4567";
const apiUrl = "https://spg.freemyip.com";

function App() {
  const [showMainModal, setShowMainModal] = useState(null);

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [loginData, setLoginData] = useState(JSON.parse(localStorage.getItem(localStorageKey)));

  const [modalData, setModalData] = useState(null);

  const [formData, setFormData] = useState(null);
  const [userData, setUserData] = useState(null);

  if (showMainModal == null && window.location.search.substr(1).length > 5) {
    setShowMainModal(true);
  } else if (showMainModal == null) {
    setShowMainModal(false);
  }

  useEffect(() => {
    if (userData) {
      let localStorageObject = {};
      localStorageObject.auth = userData.auth;
      localStorageObject.clentName = userData[0].lname + " " + userData[0]?.fname;
      localStorageObject.clentId = userData[0].id;

      localStorage.setItem(localStorageKey, JSON.stringify(localStorageObject));
      setLoginData(localStorageObject);
    }
  }, [userData]);

  useEffect(() => {
    async function loadUserData(auth) {
      if (showMainModal) {
        try {
          console.log("Loaging Data...");
          const res = await axios(apiUrl + `/exec/userData/auth=${auth}`);
          if (!res.errors) {
            res.data.data.auth = auth;

            setUserData(res.data.data);
          } else {
          }
        } catch (error) {}
      }
    }

    if (!loginData) {
      var auth = window.location.search.substring(1);
  
      console.log(auth,(!auth || (auth.length < 5 || auth ==='subscription')));

      if (!auth || (auth.length < 5 || auth ==='subscription')) {
        console.log(auth);
        auth = null;
        setShowLoginForm(true);
      }
      if (auth && !userData) loadUserData(auth);
    } else {
      if (loginData.auth && !userData) loadUserData(loginData.auth);
    }
  }, [userData, loginData, showMainModal]);

  const handleCloseSubModal = async () => {
    if (modalData.callback) await modalData.callback();

    setModalData(null);
  };
  const handleClose = () => setShowMainModal(false);

  function handleChange(event) {
    console.log(event.target.name, event.target.value);
    setFormData({ ...formData, email: event.target.value });
  }

  return (
    <>
      <div
        style={{ display: "block", cursor: "pointer", userSelect: "none" }}
        href="/"
        onClick={() => {
          setShowMainModal(!showMainModal);
        }}
      >
        Η Συνδρομη μου
      </div>

      {showMainModal && (!loginData || userData) && (
        <Modal onEscapeKeyDown={handleClose} onHide={handleClose} show={showMainModal} className="mainModal">
          <Modal.Header closeButton></Modal.Header>

          <div className="container h-100 ">
            {modalData && (
              <Modal
                className="iModal"
                show={modalData != null}
                onHide={handleCloseSubModal}
                size="md"
                enforceFocus={false}
                autoFocus
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onEscapeKeyDown={handleCloseSubModal}
                animation={false}
              >
                <Modal.Header>
                  <Modal.Title>{modalData.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalData.text}</Modal.Body>
                <Modal.Footer style={{ border: "0px" }}>
                  <Button className="modalButton" onClick={handleCloseSubModal}>
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {loginData && loginData.auth && (
              <div>
                <div className="users">
                  <div className="d-flex justify-content-center">
                    <div className="brand_logo_container">
                      <img src="./images/logo150.png" className="brand_logo" alt="Logo" />
                    </div>
                  </div>
                  {userData &&
                    userData.map((user) => (
                      <div key={user.id} className="row">
                        <div className="col">
                          <div className="userName">
                            {user.lname} {user.fname}
                          </div>

                          <div className="userSubscriptions">
                            {user.subscriptions &&
                              user.subscriptions.map((sub) => (
                                <div key={sub.id} className="userSubscription">
                                  <div className="userSubscriptionHeader">
                                    {sub.plan.label +
                                      "  (" +
                                      (sub.plan.months === 1 ? "Mηνιαία)" : sub.plan.months.toString() + " μήνες)")}
                                  </div>
                                  <div className="userSubscriptionBody">
                                    Έναρξη Συνδρομής
                                    <br /> {formatDate(sub.from)}
                                    <br />
                                    {sub.to && (
                                      <>
                                        Λήξη Συνδρομής
                                        <br /> {formatDate(sub.to)}
                                        <br />
                                      </>
                                    )}
                                    {!sub.to && (
                                      <>
                                        Ανανέωση Συνδρομής
                                        <br /> {formatDate(sub.nextCharge)}
                                        <br />
                                      </>
                                    )}
                                  </div>
                                  {sub.extraDays && sub.extraDays > 0 && (
                                    <div className="userSubscriptionFooter">
                                      Στην συνδρομή έχουν προστεθεί {sub.extraDays} ημέρες χωρίς χρέωση
                                    </div>
                                  )}
                                </div>
                              ))}
                            {!user.subscriptions && <div className="userSubscription">Δεν υπάρχουν συνδρομές</div>}
                          </div>
                          <div className="line"></div>
                          <div className="userPaymentInfo">
                            {user.debt != undefined && user.debt != null && user.debt > 0 && (
                              <>
                                <span
                                  style={{
                                    color: "darkred",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Υπάρχουν οικονομικές εκκρεμότητες ({user.debt} ευρώ)
                                </span>
                                <br />
                              </>
                            )}
                            {user.debt != undefined && user.debt != null && user.debt <= 0 && (
                              <>Δεν υπάρχουν οικονομικές εκκρεμότητες</>
                            )}
                          </div>

                          <div className="userPayment">
                            {user.payments != null && user.payments.length > 0 && (
                              <>
                                Τελευταία Πλήρωμη
                                <br />
                                {formatDate(user.payments[0].date)}
                              </>
                            )}
                            {!user.payments && <>Δεν υπάρχουν πληρωμές</>}
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="usersFooter">
                    <a
                      href="./"
                      onClick={() => {
                        let nextUrl = window.location.href.replace("/?" + loginData.auth, "");
                        if (window.location.href !== nextUrl) window.location = nextUrl;

                        setUserData(null);

                        localStorage.setItem(localStorageKey, null);
                      }}
                    >
                      Δεν θέλω να με θυμάται ο browser.
                    </a>
                    &nbsp;Για να μπορέσω να δω τα στοιχεία της συνδρομής μου στο μέλλον, θα ακολουθήσω τον σύνδεσμο που
                    μου έχει σταλεί στο email μου ή να αιτηθώ νέο.
                  </div>
                </div>
              </div>
            )}

            {showLoginForm && (
              <div className="d-flex align-content-start flex-wrap justify-content-center h-100">
                <div className="user_card">
                  <div className="d-flex justify-content-center">
                    <div className="brand_logo_container">
                      <img src="./images/logo150.png" className="brand_logo" alt="Logo" />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center form_container">
                    <form>
                      <div className="mt-4 mb-4">
                        <center>
                          Για να συνδεθείς ακολούθησε τον
                          <br />
                          σύνδεσμο που σου στείλαμε με <br />
                          email κατά την εγγραφή σου.
                          <br />
                          <br />
                          Για να σου ξαναστείλουμε τον <br />
                          σύνδεσμο, συμπλήρωσε το email <br />
                          σου και πάτα «Αποστολή»
                        </center>
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <FA name="envelope" style={{ color: "#f39c12" }} />
                          </span>
                        </div>
                        <input
                          type="text"
                          name="email"
                          className="form-control input_email"
                          onChange={handleChange}
                          value={formData ? formData.email : ""}
                          placeholder="email"
                        />
                      </div>

                      <div className="d-flex justify-content-center mt-3 login_container">
                        <Button
                          type="button"
                          name="button"
                          variant="primary"
                          className="btn login_btn"
                          onClick={async () => {
                            if (formData) {
                              let res = await axios(
                                apiUrl +
                                  `/x/b569b1afd005e5e1c843a89139d46d0527d44bd49f44a1d5242b791bd27d4faa/email=${formData.email}`
                              );
                              var data = res.data;

                              if (data.errors) {
                                setModalData({
                                  title: "Πρόβλημα",
                                  text: data.error,
                                });
                              } else {
                                data = data.data;
                                data.callback = () => {
                                  formData.email = "";
                                  setShowMainModal(false);
                                };
                                setModalData(data);
                              }
                            }
                          }}
                        >
                          Αποστολή
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

// function nextCharge(sub) {
//   moment.tz.setDefault("Europe/Athens");

//   let plan = sub.plan;
//   let from = moment(new Date(sub.from));

//   from = from.add(sub.extraDays == null ? 0 : sub.extraDays, "days");

//   if (sub.to != null && moment(sub.to).isBefore(Date.now())) {
//     return null;
//   } else {
//     let to = moment(Date.now());

//     let months =
//       to.year() * 12 + to.month() - (from.year() * 12 + from.month());

//     console.log("months", months, to.month() + 1, from.month() + 1);

//     if (to.date() >= from.date()) months++;

//     let payPeriods = Math.floor(months / plan.months);

//     if (payPeriods * plan.months < months) payPeriods++;

//     return new Date(
//       from.year(),
//       from.month() + payPeriods * plan.months,
//       from.date()
//     );
//   }
// }

export default App;
